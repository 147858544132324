let profile_data = {
	"bio": "\
		I'm deeply passionate about exploring the dynamic intersection of culture and society. \
		With a background spanning art spaces and the consumer industry, I've witnessed firsthand how cultural phenomena shape our lives. \
		My interest in cultural studies stems from a desire to understand these influences and their evolving expressions.	\
		<br><br> \
		My curiosity extends to the development of museums in China, particularly within the Greater Bay Area. \
		I am fascinated by how museums are adopting cultural innovation, commercialization, and public engagement, with a keen eye on the unique development paths of private museums in the region. \
		This interest is part of a broader ambition to explore the interplay between culture and social development.\
		",
	"name": "Xinning Feng (Ivy) 冯欣宁",
	"portrait": [{
		"path": "/portraits/2.jpg",
		"caption": ""
	}]
};

export function get_about() {
	return new Promise(r=>r(profile_data));
}